<template>

  <section class="app-datasets">

    <img
      class="app-datasets-title"
      alt="DATASETS_TITLE"
      src="/img/index/datasets-title.png">

    <ul
      class="dataset-cards"
      role="content">

      <li v-for="(item, index) in list"
        :class="item.status"
        :key="index"
        :style="{ transform: 'translateX(' + item.offsetX + 'px)' }"
        @click="checkDetail(item)">

        <img
          alt="DATASET_POSTER"
          class="poster"
          :src="item.poster">

        <span class="title">{{ item.title }}
        </span>

        <div class="date-month">

          <span class="date">{{ item.date }}
          </span>

          <span class="month">{{ item.month }}
          </span>

        </div>

      </li>

      <div
        class="carousel-control vsl-left"
        :class="disabled.left"
        @click="scroll(+1)">

        <i class="el-icon-back">
        </i>

      </div>

      <div
        class="carousel-control vsl-right"
        :class="disabled.right"
        @click="scroll(-1)">

        <i class="el-icon-right">
        </i>

      </div>

    </ul>

    <div class="app-dataset-more">

      <span>Learn More</span>

      <img
        class="app-dataset-right"
        alt="APP_RIGHT"
        src="/img/app-right.png"/>

    </div>

  </section>

</template>

<script>
import * as api from '@/configs/api'
export default {
  name: 'app-datasets',
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL || '',
      carousel: {
        data: 1
      },
      disabled: {
        left: '',
        right: ''
      },
      list: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    checkDetail (item) {
      const url = `/data/metadata-special-detail?id=${item.id}`
      window.open(url, '_blank')
    },
    initData () {
      const params = new FormData()
      params.append('offset', 1)
      params.append('limit', 5)
      params.append('isEn', 1)
      params.append('specialMark', 1)

      this.axios.post(api.metadata.page, params).then((response) => {
        const res = response.data
        if (res.code === 200) {
          const data = res.data.records.map(item => {
            return {
              date: 15,
              id: item.id,
              month: 'Jan',
              poster: `${this.baseURL}${item.imagesList[0].filePath}`,
              title: item.enName,
              url: `/data/metadata-special-detail?id=${item.id}`
            }
          })
          this.list = data.slice()
          this.scroll(0)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    scroll (forward) {
      const scale = this.list.length - 2
      const current = this.carousel.data
      const target = current + forward * 1

      if (
        (current === 1 && forward < 0) ||
        (current === scale && forward > 0)
      ) {
        return null
      }

      this.carousel.data = target

      this.disabled.left = target === scale ? 'disabled' : ''
      this.disabled.right = target === 1 ? 'disabled' : ''

      this.list = this.list.map((item, index) => {
        const active = index === this.carousel.data ? 'active' : ''
        const offsetExisted = typeof item.offsetX === 'number'
        const offsetBefore = offsetExisted ? item.offsetX : (index * 350)
        const offsetX = offsetBefore - 350 * forward
        const status = Math.abs(index - target) < 2 ? 'move' : 'hidden'

        item.offsetX = offsetX
        item.status = active || status

        return item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-datasets {
  background-color: rgba(20, 63, 140, 1);
  box-sizing: border-box;
  padding: {
    bottom: 50px;
    top: 10px;
  };
}

.app-datasets-title {
  height: 60px;
  margin-top: 61px;
  width: 412px;
}

.dataset-cards {
  color: white;
  //display: flex;
  //flex-direction: row;
  //justify-content: space-between;
  height: 470px;
  margin: {
    top: 20px
  };
  padding: {
    left: 0px;
  };
  position: relative;

  li {
    background-color: rgba(239, 243, 249, 1);
    border: {
      top: {
        color: transparent;
        style: solid;
        width: 3px;
      }
    };
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 457px;
    left: 120px;
    list-style: none;
    overflow: hidden;
    padding: {
      bottom: 20px;
      left: 0;
      right: 5px;
      top: 0;
    };
    position: absolute;
    top: 0;
    transition-duration: 1.3s;
    width: 290px;
    &:hover {
      border: {
        top: {
          color: rgba(0, 117, 255, 1);
        }
      };
      transition-duration: .5s;

      .poster {
        height: 260px;
        transform: scale(1.1, 1.1);
        transition-duration: .5s;
        overflow: hidden;
      }
    }
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  //li:nth-child(4n) {
  //  background-color: rgba(153, 102, 204, 1);
  //}
  //li:nth-child(4n + 1) {
  //  background-color: rgba(0, 204, 204, 1);
  //}
  //li:nth-child(4n + 2) {
  //  background-color: rgba(255, 204, 51, 1);
  //}
  //li:nth-child(4n + 3) {
  //  background-color: rgba(0, 204, 255, 1);
  //}
  .poster {
    height: 200px;
    margin: {
      bottom: 20px;
    };
    overflow: hidden;
    transition-duration: .5s;
    width: 290px;
  }
  .title {
    color: rgba(51, 51, 51, 1);
    display: block;
    font-size: 16px;
    padding: {
      left: 15px;
      right: 15px;
    };
    text-align: left;
  }
  .date-month {
    border: {
      color: rgba(230, 236, 238, 1);
      style: solid;
      width: 1px;
    }
    bottom: 20px;
    color: rgba(180, 187, 187, 1);
    font: {
      size: 14px
    };
    height: 34px;
    position: absolute;
    right: 20px;
    width: 34px;

    .date {
      display: block;
      line-height: 20px;
    }
    .month {
      display: block;
      font-size: 12px;
      line-height: 10px;
    }
  }
}

.carousel-control {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 32px;
  height: 64px;
  line-height: 64px;
  position: absolute;
  top: 200px;
  width: 64px;
}
.carousel-control.disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}
.carousel-control.vsl-left {
  left: -32px;
}
.carousel-control.vsl-right {
  right: -32px;
}
.carousel-control i {
  color: #CCC;
}

.app-dataset-more {
  background-color: rgba(63, 126, 247, 1);
  border-radius: 2px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  margin: {
    left: auto;
    right: auto;
    top: 43px;
  };
  width: 166px;

  &:hover {
    background-color: rgba(33, 103, 236, 1);
  }

  .app-dataset-right {
    height: 10px;
    margin-left: 8px;
    width: 6px;
  }
  span {
    color: rgba(255, 255, 255, 1);
  }
}
</style>
