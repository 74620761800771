<template>

  <section class="app-points">

    <div class="app-points-item app-points-item-info">

      <img
        class="app-points-title"
        alt="POINTS_TITLE"
        src="/img/index/points-title.png">

      <ul class="app-points-list">

        <li v-for="(item, i) in points"
          :key="i">

          <i :class="item.icon"></i>

          <div>

            <h3>{{ item.label }}</h3>

            <span>{{ item.content }}</span>

          </div>

        </li>

      </ul>

      <div class="app-points-more">

        <span>Learn More</span>

        <img
          class="app-points-right"
          alt="APP_RIGHT"
          src="/img/app-right.png"/>

      </div>

    </div>

    <div class="app-points-item app-points-item-pic">

      <img
        alt="POINTS_BACKGROUND"
        src="/img/index/points-pic.png">

    </div>

  </section>

</template>

<script>
export default {
  name: 'app-points',
  data () {
    return {
      points: [
        {
          content: 'CODC-GOSD collect all available in situ ocean observations with rigorous Quality-Control and Bias Correction, therefore provide the state-op-the-art database for a wide range of oceanographic and climate applications',
          icon: 'el-icon-sunny',
          label: 'BRIEF INTRODUCTION'
        },
        {
          content: 'temperature, salinity',
          icon: 'el-icon-s-operation',
          label: 'VARIABLES'
        },
        {
          content: 'Argo, CTD, XBT, Glider, XBT, Bottle',
          icon: 'el-icon-trophy',
          label: 'INSTRUMETS'
        },
        {
          content: '1900-present; global ocean',
          icon: 'el-icon-chat-line-round',
          label: 'COVERAGE'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-points {
  background-color: rgba(26, 69, 146, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 760px;
  position: relative;
}
.app-points-item {
  width: 50%;
}
.app-points-item-info {
  position: relative;

  img.app-points-title {
    height: 21px;
    position: absolute;
    right: 98px;
    top: 120px;
    width: 500px;
  }
}
.app-points-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  position: absolute;
  right: 218px;
  top: 194px;
  width: 382px;

  li {
    color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    text-align: left;
    &:not(:last-child) {
      margin: {
        bottom: 32px;
      };
    }

    i {
      font-size: 30px;
      margin: {
        right: 20px;
      };
    }
    h3 {
      font: {
        weight: normal;
      };
      margin: {
        bottom: 7px;
        top: 0;
      };
    }
    span {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.app-points-more {
  background-color: rgba(63, 126, 247, 1);
  border-radius: 2px;
  bottom: 70px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 435px;
  width: 166px;

  &:hover {
    background-color: rgba(33, 103, 236, 1);
  }

  .app-points-right {
    height: 10px;
    margin-left: 8px;
    width: 6px;
  }
  span {
    color: rgba(255, 255, 255, 1);
  }
}
.app-points-item-pic {
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
