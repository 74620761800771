<template>

  <section class="app-banner">

     <video
       autoplay
       class="app-banner-video"
       loop
       muted
       poster="/img/bg-banner.png"
       src="/video/banner.mp4">
    </video>

    <img
      class="app-banner-title"
      alt="BANNER_TITLE"
      src="/img/index/banner-title.png">

    <div class="app-banner-more">

      <span>Learn More</span>

      <img
        class="app-banner-right"
        alt="APP_RIGHT"
        src="/img/app-right.png"/>

    </div>

    <img
      class="app-banner-bg"
      alt="BANNER-BACKGROUND"
      src="/img/index/banner-bg.png">

  </section>

</template>

<script>

export default {
  name: 'app-banner',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.app-banner {
  height: 768px;
  position: relative;
  width: 100vw;
}
.app-banner-video {
  min-width: 1366px;
  width: 100%;
  z-index: 0;
}
.app-banner-title {
  height: 149px;
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 929px;
}
.app-banner-more {
  background-color: rgba(63, 126, 247, 1);
  border-radius: 2px;
  cursor: pointer;
  height: 50px;
  left: 50%;
  line-height: 50px;
  position: absolute;
  top: 58%;
  transform: translateX(-50%);
  width: 166px;

  &:hover {
    background-color: rgba(33, 103, 236, 1);
  }

  .app-banner-right {
    height: 10px;
    margin-left: 8px;
    width: 6px;
  }
  span {
    color: rgba(255, 255, 255, 1);
  }
}
.app-banner-bg {
  bottom: 0;
  height: 277px;
  left: 0;
  position: absolute;
  min-width: 1366px;
  width: 100%;
}
</style>
