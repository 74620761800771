<template>

  <section
    class="app-links">

    <div
      role="content">

      <img
        alt="LINKS_TITLE"
        src="/img/index/links-title.png">

      <ul class="links-flow">

        <a v-for="(item, index) in list"
          :href="item.url"
          :key="index"
          target="_blank"
          :style="`background-image: url(${ item.poster });`">

          <p>{{ item.english }}</p>

          <!-- <span>{{ item.label }}</span> -->

        </a>

      </ul>

    </div>

  </section>

</template>

<script>
export default {
  name: 'app-links',
  data () {
    return {
      list: [
        {
          english: 'INTERNATIONAL QUALITY CONTROLLED OCEAN DATABAS',
          label: '国际海洋数量控制数据库',
          poster: '/img/index/links-icon-1.png',
          url: 'http://www.iquod.org.cn/'
        },
        {
          english: 'DOMAIN CLOUD PLATFORM',
          label: '领域云平台',
          poster: '/img/index/links-icon-2.png',
          url: 'http://portal.qdio.ac.cn/'
        },
        {
          english: 'VISUALIZATION PLATFORM',
          label: '可视化平台',
          poster: '/img/index/links-icon-3.png',
          url: 'http://casearthocean.qdio.ac.cn/oceanVisual/'
        }
        // {
        //   english: '',
        //   label: '',
        //   poster: '/img/link-global-change-response.png',
        //   url: 'http://www.mecp.org.cn/'
        // },
        // {
        //   english: '',
        //   label: '',
        //   poster: '/img/link-cas-pilot-project-a.png',
        //   url: 'http://wposdim.qdio.ac.cn/'
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-links {
  background-color: rgba(8, 48, 119, 1);
  padding: {
    top: 60px;
  };

  img {
    height: 59px;
    width: 101px;
  }
}

.links-flow {
  align-content: space-between;
  background-image: url('/img/index/links-bg.png');
  background-size: 100% auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font: {
    family: 'DINProMedium';
  };
  justify-content: space-between;
  margin: {
    bottom: 0;
    top: 0;
  };
  padding: {
    bottom: 100px;
    left: 0px;
    top: 86px;
  };

  a {
    align-items: center;
    //background-color: rgba(4, 57, 152, .6);
    background-size: 100% 100%;
    border: {
      color: rgba(4, 71, 191, 1);
      style: solid;
      width: 2px;
    };
    box-shadow: 0px 4px 14px 0px rgba(2, 23, 61, 0.34);
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 128px;
    justify-content: center;
    list-style: none;
    text-decoration: transparent;
    width: 25%;

    &:hover {
      border: {
        color: rgba(0, 117, 255, 1);
        width: 2px;
      };
    }

    p {
      font {
        size: 16px;
      };
      margin: {
        bottom: 10px;
        top: 0;
      };
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
