<template>

  <div class="app-homepage">

    <app-banner>
    </app-banner>

    <app-points>
    </app-points>

    <app-datasets>
    </app-datasets>

    <app-links>
    </app-links>

  </div>

</template>

<script>
import appBanner from './banner'
import appDatasets from './datasets'
import appLinks from './links'
import appPoints from './points'

export default {
  name: 'app-homepage',
  components: {
    appBanner,
    appDatasets,
    appLinks,
    appPoints
  }
}
</script>

<style lang="scss" scoped>
.app-homepage {
  background-color: white;
  overflow-x: hidden;
}
</style>
